import Vue from 'vue';
import {TranslateResult} from 'vue-i18n';
import {object, Schema} from 'yup';
import mapValues from 'lodash/mapValues';

export interface SelectItem {
  value?: string | number;
  text?: string | TranslateResult;
  disabled?: boolean;
  divider?: boolean;
  header?: string;
}

export interface Field<T> {
  name: string;
  schema?: Schema<T>;
  default?: T;
  noSortEdges?: [string, string];
}

type DefaultValues<T> = Partial<Record<keyof T, any>>;

export function buildSelectItems(items: Record<string, string>, vue: Vue): SelectItem[] {
  return Object.entries<string>(items).map<SelectItem>(
    ([value, translationKey]) => ({
      value,
      text: vue.$t(translationKey) as string,
    })
  );
}

export function buildTranslatedStringArray(items: Record<string, string>): () => Array<string> {
  return function (this: Vue) {
    return Object.entries<string>(items).map(
      ([value, translationKey]) => (this.$t(translationKey) as string
      )
    );
  }
}


export function selectItems(items: Record<string, string>, sortText?: boolean): () => SelectItem[] {
  return function (this: Vue) {
    const selectItems: SelectItem[] = buildSelectItems(items, this);
    if (sortText) {
      selectItems.sort((a, b) => {
        return a.text && b.text
          ? (a.text as string).localeCompare(b.text as string)
          : 0;
      });
    }

    return selectItems;
  };
}

export function createTranslations(
  values: readonly string[],
  translationPath: string
): Record<string, string> {
  return Object.fromEntries(
    values.map(value => [value, `${translationPath}.${value}`])
  );
}

export function transformNumber(val: any) {
  if (val == '' || Number.isNaN(val)) {
    return undefined;
  }
  return val;
}

export function createSchema<T = any>(fields: Field<any>[]) {
  // see https://dev.to/gabrielterriaga/how-to-validate-two-fields-that-depend-on-each-other-with-yup-1ccg
  const noSortEdges = [] as Array<[string, string]>;

  const schema = fields.reduce((acc, field) => {
    if (field.noSortEdges) {
      noSortEdges.push(field.noSortEdges);
    }
    if (field.schema) {
      acc[field.name] = field.schema;
    }
    return acc;
  }, {} as any);
  return object<any>().shape(schema, noSortEdges);
}

export function createDefaultValues<T extends object>(
  values: DefaultValues<T>
): T {
  return mapValues(values, (value: any) =>
    typeof value === 'function' ? value(values) : value
  ) as T;
}
