/* eslint-disable @typescript-eslint/camelcase */
import {createTranslations} from '@/utils/form';
import {Address, Party, Person} from '@/models/entities';
import {PartyRelationType} from "@/enums/partyRelationType";

// events
export const BEFORE_ROUTE_LEAVE_EVENT = 'beforeRouteLeave';

// local storage keys
export const LATEST_CASES_KEY = 'latest_cases';

// getters
export const CASE_API_PARAMS_GETTER = 'case/apiParams';
export const ESTATE_INVENTORY_API_PARAMS_GETTER = 'estateInventory/apiParams';
export const ESTATE_DIVISION_API_PARAMS_GETTER = 'estateDivision/apiParams';

// case types
export const CASE_TYPES = ['ESTATE'] as const;
export const CASE_TYPE_TRANSLATIONS = createTranslations(
  CASE_TYPES,
  'generic.caseTypes'
);

export const CASE_DOCUMENT_TYPES = [
  'ESTATE_INVENTORY',
  'ESTATE_DIVISION',
  'ESTATE_DISTRIBUTION',
  'ESTATE_SUMMONS',
  'ESTATE_DIVISION_DISTRIBUTION',
  'ADDITIONAL_ESTATE_INVENTORY'
] as const;

export const CASE_DOCUMENT_TYPE_TRANSLATIONS = createTranslations(
  CASE_DOCUMENT_TYPES,
  'generic.caseDocumentTypes'
);

// marital status
export const MARITAL_STATUSES = [
  'MARRIED',
  'WIDOW',
  'DIVORCED',
  'NOT_MARRIED',
  'PARTNER',
] as const;

export const LEGAL_GENDERS = [
  'MALE',
  'FEMALE',
] as const;

export const MARITAL_STATUS_TRANSLATIONS = createTranslations(
  MARITAL_STATUSES,
  'generic.maritalStatus'
);

export const LEGAL_GENDER_TRANSLATIONS = createTranslations(
  LEGAL_GENDERS,
  'generic.legalGenders'
);

export const TARGET_ENTITY_TYPE = ['PARTY', 'CASE_ITEM_PERSON'] as const;

// party roles
export const PARTY_ROLES = [
  'ESTATE_PARTY', // dödsbodelagägare
  'REMAINDERMAN', // efterarvinge
  'LEGATEE_REMAINDERMAN', // testamentarisk efterarvinge
  'RESIDUARY_LEGATEE', // universell testamentstagare
  'LEGATEE', // legatarie
  'OTHER', // övrig
  'EXPIRED', // utgått
  'NONE', // ingen
  'PROCEEDING_CREATOR', // förrättningsman
  'ESTATE_EXECUTOR', // bouppgivare
  'DEVISOR', // arvlåtare
  'ORGANIZATION',
  'PREVIOUSLY_DECEASED', // tidigare avliden
  'NO_LONGER_ESTATE_PARTY', // Ej längre dödsbodelägare
] as const;

export const PARTY_ADDITIONAL_ROLES = [
  'ESTATE_PARTY', // dödsbodelagägare
  'REMAINDERMAN', // efterarvinge
  'LEGATEE_REMAINDERMAN', // testamentarisk efterarvinge
  'RESIDUARY_LEGATEE', // universell testamentstagare
  'LEGATEE', // legatarie
  'PROCEEDING_CREATOR', // förrättningsman
  'ESTATE_EXECUTOR', // bouppgivare
  'DEVISOR', // arvlåtare
] as const;

export const PARTY_ROLE_TRANSLATIONS = createTranslations(
  PARTY_ROLES,
  'generic.partyRoles'
);

export const PARTY_ADDITIONAL_ROLE_TRANSLATIONS = createTranslations(
  PARTY_ADDITIONAL_ROLES,
  'generic.partyRoles'
);

// party relations
export const PARTY_RELATIONS = [
  'SPOUSE',
  'SIBLING',
  'CHILD',
  'PARENT',
  'PARTNER', // Sambo
  'OTHER_RELATION', // e.g. One night stand
  'COUSIN',
  'CHILD_FROM_PREVIOUS_MARRIAGE',
  'EX_SPOUSE',
  'HALF_SIBLING',
  'PATERNAL_UNCLE',
  'MATERNAL_UNCLE',
  'PATERNAL_AUNT',
  'MATERNAL_AUNT',
  'GRAND_CHILD',
  'NIECE',
  'HALF_NIECE'
] as const;

export const CHILD_RELATION_TYPES = [
  PartyRelationType.CHILD,
  PartyRelationType.CHILD_FROM_PREVIOUS_MARRIAGE
]

export const CHILD_AND_PARENT_RELATION_TYPES = [
  PartyRelationType.CHILD,
  PartyRelationType.CHILD_FROM_PREVIOUS_MARRIAGE,
  PartyRelationType.PARENT
]

export const PARTY_RELATION_TRANSLATIONS = createTranslations(
  PARTY_RELATIONS,
  'generic.partyRelations'
);

export const REVERSE_RELATIONS = { // TODO - this might be where the "issue" with relations is, they are missing from this list
  PARENT: 'CHILD',
  CHILD: 'PARENT',
  CHILD_FROM_PREVIOUS_MARRIAGE: 'PARENT',
};
export const REVERSE_RELATION_TYPES = Object.keys(REVERSE_RELATIONS);

// asset categories
export const ASSET_TYPES = ['ASSET', 'LIABILITY'] as const;
// Note that the order of the elements are important!
export const ASSET_CATEGORIES = [
  'REALTY', // fast egendom
  'CONDOMINIUM', // bostadsrätt
  'PLOT_RIGHT', // tomträtt
  'BANK_ACCOUNT', // bankkonto
  'SHARE', // aktie
  'FUND', // fond
  'PERSONALTY', // lösöre
  'INSURANCE', // försäkring
  'SHARE_IN_OTHER_ESTATE', // andel i annat oskiftat dödsbo
  'OTHER_ASSET', // övrigt
] as const;

export const ASSET_CATEGORY_TRANSLATIONS = createTranslations(
  ASSET_CATEGORIES,
  'generic.ownership.assetCategories'
);

// Antal
export const ASSET_COUNT_CATEGORIES = ['SHARE'] as const;

export const ASSET_SHARE_CATEGORIES = [
  'BANK_ACCOUNT',
  'CONDOMINIUM',
  'FUND',
  'OTHER_ASSET',
  'PERSONALTY',
  'PLOT_RIGHT',
  'REALTY',
  'SHARE_IN_OTHER_ESTATE',
] as const;

// Kurs
export const OWNERSHIP_RATE_CATEGORIES = [
  'SHARE',
  'FUND'
] as const;

export const OWNERSHIP_BAKLÄNGES_CATEGORIES = [
  'FUND'
] as const;

// asset types
export const OWNERSHIP_TYPES = [
  'PRIVATE',
  'MARITAL',
  'JOINT',
  'PERSONAL',
  'NONE'
] as const;

// division types
export const ESTATE_DIVISION_IMPORT_TYPE = [
  'ESTATE_INVENTORY',
  'TRANSACTIONS',
  'DAILY_BALANCE',
] as const;

export const ESTATE_DIVISION_IMPORT_TYPE_TRANSLATIONS = createTranslations(
  ESTATE_DIVISION_IMPORT_TYPE,
  'generic.estateDivision.importType'
);

export const OWNERSHIP_TYPE_TRANSLATIONS = createTranslations(
  OWNERSHIP_TYPES,
  'generic.ownership.ownershipTypes'
);

export const OWNERSHIP_OWNERS = ['DECEASED', 'SPOUSE', 'PARTNER'] as const;
export const OWNERSHIP_OWNER = {
  DECEASED: 'DECEASED',
  SPOUSE: 'SPOUSE',
  PARTNER: 'PARTNER',
};

export const OWNERSHIP_OWNER_TRANSLATIONS = createTranslations(
  OWNERSHIP_OWNERS,
  'generic.ownership.ownershipOwner'
);

// liability categories
export const LIABILITY_CATEGORIES = [
  'DEBT', // skuld
  'BILL', // räkning
  'RENT', // hyra
  'OTHER_LIABILITY', // övrigt
  'OUTGOING', // avgående poster
  'FUNERAL_COSTS',
] as const;

export const LIABILITY_CATEGORY_TRANSLATIONS = createTranslations(
  LIABILITY_CATEGORIES,
  'generic.ownership.liabilityCategories'
);

export const LIABILITY_COUNT_CATEGORIES = [] as const;
export const LIABILITY_SHARE_CATEGORIES = ['DEBT', 'BILL', 'RENT'] as const;

export const ASSET_TYPE_CATEGORIES = [
  ...ASSET_CATEGORIES,
  ...LIABILITY_CATEGORIES,
] as const;

// merged types
export const OWNERSHIP_SHARE_CATEGORIES = [
  ...ASSET_SHARE_CATEGORIES,
  ...LIABILITY_SHARE_CATEGORIES,
] as const;

export const OWNERSHIP_INTEREST_CATEGORIES = [
  ...["BANK_ACCOUNT"],
  ...LIABILITY_CATEGORIES,
] as const;

export const OWNERSHIP_COUNT_CATEGORIES = [
  ...ASSET_COUNT_CATEGORIES,
  ...LIABILITY_COUNT_CATEGORIES,
];

// party representations
export const PARTY_REPRESENTATIONS = [
  'PERSONALLY',
  'AUDIO',
  'VIDEO',
  'NOT_REPRESENTED',
  'POWER_OF_ATTORNEY',
  'POWER_OF_CUSTODIAN',
  'REPRESENTED_NOT_SUMMONED'
] as const;

export const PARTY_REPRESENTATION_TRANSLATIONS = createTranslations(
  PARTY_REPRESENTATIONS,
  'generic.party.representationTypes'
);

// keys
export const PARTY_PROPERTIES: ReadonlyArray<keyof Party> = [
  'id',
  'summon',
  'represented',
  'representedBy',
  'role',
  'additionalRoles',
  'relations',
  'roleReason',
] as const;
export const PERSON_PROPERTIES: ReadonlyArray<keyof Person> = [
  'firstName',
  'givenName',
  'lastName',
  'maidenName',
  'personalIdNumber',
  'citizenship',
  'email',
  'phone',
  'birthdate',
  'deceased',
  'deceasedDate',
  'unknownDeceasedDate',
  'foundDeceased',
  'maritalStatus',
  'legalGender',
  'juridicalPerson',
  'unrestrictedPersonalIdNumber',
] as const;
export const ADDRESS_PROPERTIES: ReadonlyArray<keyof Address> = [
  'address1',
  'address2',
  'address3',
  'postcode',
  'city',
  'country',
] as const;

export const PARTY_SUMMONS_PROPERTIES = [
  'id',
  'summon',
  'represented',
  'representedBy',
] as const;

export const EntityOwnerTypes = [
  'USER_ACCOUNT',
  'EXTERNAL_SOURCE',
  'OFFICE',
  'ORGANIZATION_GROUP'
] as const;

// transactions
export const TRANSACTION_TYPES = ['CLIENT_FUNDS', 'BANK_ACCOUNT'] as const;

export const TRANSACTION_TYPE_TRANSLATIONS = createTranslations(
  TRANSACTION_TYPES,
  'generic.transactions.transactionTypes'
);

export const NO_LONGER_ESTATE_PARTY_REASONS = [
  'APPROVAL_OF_WILL', // Godkännande av testamente
  'WAIVER_OF_INHERITANCE', // Arvsavstående
] as const;

export const NO_LONGER_ESTATE_PARTY_REASON_TRANSLATIONS = createTranslations(
  NO_LONGER_ESTATE_PARTY_REASONS,
  'generic.partyRoleReasons'
);
